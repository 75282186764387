/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.match-height').matchHeight();
        $('.match-height-2').matchHeight();

        var sliders3D = document.querySelectorAll('.slider3D');

        function stopSlider(slider, frontpan) {
          slider.onmousemove = null;
          frontpan.style.cursor = 'grab';
        }

        function showImage(slider, image) {
          var imgShow = slider.querySelector('img.show');

          if (imgShow) {
            imgShow.classList.remove('show');
          }

          image.classList.add('show');
        }

        if (sliders3D.length > 0) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            var _loop = function _loop() {
              var slider = _step.value;
              var frontpan = slider.querySelector('.frontpan');
              var images = slider.querySelectorAll('img');
              var mousePositionX = 0;
              var index = 0;
              var frame = 10;
              showImage(slider, images[0]);

              slider.onmouseup = function (e) {
                stopSlider(slider, frontpan);
              };

              slider.onmouseleave = function (e) {
                stopSlider(slider, frontpan);
              };

              slider.onmousedown = function () {
                mousePositionX = event.clientX;
                frontpan.style.cursor = 'grabbing';

                slider.onmousemove = function (e) {
                  if (event.clientX > mousePositionX + frame) {
                    mousePositionX = event.clientX;

                    if (index == images.length - 1) {
                      index = 0;
                    } else {
                      index += 1;
                    }
                  } else if (event.clientX < mousePositionX - frame) {
                    mousePositionX = event.clientX;

                    if (index == 0) {
                      index = images.length - 1;
                    } else {
                      index -= 1;
                    }
                  }

                  showImage(slider, images[index]);
                };
              };
            };

            for (var _iterator = sliders3D[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              _loop();
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
